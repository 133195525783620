import Typography from 'rv-components-onze/src/Typography'
import Img from 'components/Img'
import Button from 'rv-components-onze/src/Button'

import enhancer from './hooks'

const AnyQuestionSection = ({ openFAQ }) => {
  return (
    <section className="section-question">
      <div className="container">
        <Img
          className="section-question__image"
          src="/rebranding-faq-img.png"
          alt="Fale conosco"
        />

        <div className="section-question__text-content">
          <Typography
            as="h4"
            variant="headline4"
            className="section-question__title">
            Agora é a hora!
          </Typography>

          <Typography
            as="p"
            variant="body1"
            className="section-question__description">
            Acesse nossa central de ajuda com as perguntas mais frequentes.
          </Typography>

          <Button
            label="Tire suas dúvidas"
            iconId="right-arrow"
            iconPosition="right"
            buttonStyle="text"
            customClass="section-question__button"
            onClick={openFAQ}
          />
        </div>
      </div>
    </section>
  )
}

export default enhancer(AnyQuestionSection)
