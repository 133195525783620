import Typography from 'rv-components-onze/src/Typography'
import Icon from 'rv-components-onze/src/Icon'

const PrivacyItem = ({ title, description }) => {
    return(
        <li className="safety-privacy-item">
            <Typography as="h3" variant="subtitle" className="safety-privacy-item__title">
                <Icon 
                    iconId="right-arrow"
                />
                {title}
            </Typography>
            <Typography as="p" variant="body1" className="safety-privacy-item__description">
                {description}
            </Typography>
        </li>
    )
}


const Privacy = () => {
    return(
        <section className="safety-privacy container">
            <Icon 
                iconId="grafismo-onze2"
                customClass="safety-privacy__grafismo"
            />

            <Typography as="h2" variant="headline4" className="safety-privacy__title">
                Respeitamos sua privacidade
            </Typography>

            <ul className="safety-privacy__list">
                <PrivacyItem 
                    title='LGPD'
                    description={['Nossa ',<a href="https://www.onze.com.br/politica-de-privacidade" target="_blank" className="safety-privacy-item__link">política de privacidade</a> , ' está de acordo com a nova LGPD.']}
                />

                <PrivacyItem 
                    title='Instruções CVM'
                    description={['Todos os processos internos respeitam as ',<a href="https://www.onze.com.br/blog/wp-content/uploads/2019/08/manual-de-compliance.pdf" target="_blank" className="safety-privacy-item__link">instruções da CVM</a> , ' para garantir a privacidade financeira dos nossos clientes.']}
                />
            </ul>
        </section>
    )
}

export default Privacy