import Img from 'components/Img'
import Icon from 'rv-components-onze/src/Icon'
import Typography from 'rv-components-onze/src/Typography'

const LicenseCard = ({ title, description, cta, ctaLink, imgSrc, alt }) => {
  return (
    <li className="safety-license-card">
      <div className="safety-license-card__text">
        <Typography
          as="h3"
          variant="headline4"
          className="safety-license-card__title">
          {title}
        </Typography>

        <Typography
          as="p"
          variant="body1"
          className="safety-license-card__description">
          {description}
        </Typography>

        <a href={ctaLink} target="_blank" className="safety-license-card__cta">
          <Typography as="span" variant="body2">
            {cta}
          </Typography>
          <Icon iconId="right-arrow" />
        </a>
      </div>
      <Img src={imgSrc} className="safety-license-card__image" alt={alt} />
    </li>
  )
}

const Licenses = () => {
  return (
    <section className="safety-licenses container">
      <Typography
        as="h2"
        variant="headline4"
        className="safety-licenses__title">
        Nossos reguladores e licenças
      </Typography>

      <Typography as="p" variant="body1" className="safety-licenses__subtitle">
        A Onze é uma corretora regulada pela SUSEP e uma gestora autorizada e
        regulada pela CVM e ANBIMA.
      </Typography>

      <ul className="safety-licenses__list">
        <LicenseCard
          title="CVM"
          description="A Comissão de Valores Mobiliários (CVM) é o órgão que fiscaliza, normatiza, disciplina e desenvolve o mercado de valores mobiliários no Brasil. A Onze é uma gestora credenciada e fiscalizada pela CVM."
          cta="Confira a Onze no site da CVM"
          ctaLink="http://sistemas.cvm.gov.br/?CadGeral"
          imgSrc="/img-seguranca-logos-CVM.png"
          alt="CVM"
        />

        <LicenseCard
          title="ANBIMA"
          description="A Anbima é a Associação Brasileira das Entidades dos Mercados Financeiro e de Capitais. A Onze é aderente aos códigos de gestão e de melhores práticas do setor, que dão mais transparência e segurança ao investidor final."
          cta="Confira a Onze no site da ANBIMA"
          ctaLink="https://www.anbima.com.br/pt_br/autorregular/autorregulacao-e-adesao/instituicoes-autorreguladas.htm"
          imgSrc="/img-seguranca-logos-Anbima.png"
          alt="ANBIMA"
        />

        <LicenseCard
          title="SUSEP"
          description="A Super Intendência de Seguros Privados (SUSEP) é o órgão responsável pelo controle e fiscalização dos mercados de seguro, previdência privada aberta, capitalização e resseguro. A Onze é uma corretora certificada pela SUSEP para distribuir seus planos de previdência privada."
          cta="Confira a Onze no site da SUSEP"
          ctaLink="https://www2.susep.gov.br/safe/Corretores/pesquisa"
          imgSrc="/img-seguranca-logos-susep.png"
          alt="SUSEP"
        />
      </ul>
    </section>
  )
}

export default Licenses
