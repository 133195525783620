import isClient from 'utils/isClient'

const useRedirect = () => {
  const openFAQ = () => {
    isClient() && window.open(
      'https://onze.zendesk.com/hc/pt-br'
    )
  }

  return {
    openFAQ,
  }
}

export default useRedirect