import Img from 'components/Img'
import Icon from 'rv-components-onze/src/Icon'
import Typography from 'rv-components-onze/src/Typography'

const Hero = () => {
    return (
        <section className="safety-hero" data-show>

            <div className="container">
                <div className="safety-hero__icon-border">
                    <Icon 
                        iconId="lock"
                        customClass="safety-hero__icon"
                    />
                </div>

                <div className="safety-hero__text">
                    <Typography as="h1" variant="headline5" weight="light" className="safety-hero__title">
                        A Onze é segura?
                    </Typography>
                    <Typography as="p" variant="body1" className="safety-hero__subtitle">
                        A Onze está rigorosamente dentro das normas dos mercados financeiro, de seguros e de proteção de dados do país.
                    </Typography>
                </div>
            
                <Img 
                    src="/rebranding-safety-2-img.png"
                    className="safety-hero__img"
                    aria-label="Ilustração de cadeado grande com moedas na frente"
                />
            </div>

        </section>
    )
}

export default Hero