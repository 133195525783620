import Header from 'components/Header'
import FooterNew from 'components/FooterNew'
import Menu from 'components/Menu'
import Sidebar from 'components/Sidebar'
import SectionForm from 'components/SectionForm'
import AnyQuestionSection from 'components/AnyQuestionSection'

import Hero from './components/Hero'
import OnzeAndZurich from './components/OnzeAndZurich'
import Licenses from './components/Lincenses'

import {Fragment} from 'react'
import Privacy from './components/Privacy'
import MoneyProtection from './components/MoneyProtection'

import enhancer from './hooks'

const Safety = () => {
    return(
        <Fragment>
            <Header hasTransition/>
            <Hero />
            <Menu />
            <Sidebar>
                <SectionForm customClass="sidebar" />
            </Sidebar>
            <OnzeAndZurich />
            <Licenses />
            <MoneyProtection />
            <Privacy />
            <AnyQuestionSection />
            <FooterNew />
        </Fragment>
    )
}

export default enhancer(Safety)